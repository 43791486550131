import React from "react";
import { Box, Typography } from "@mui/material";
import { eachDayOfInterval, format, parseISO, subDays } from "date-fns";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PastIncidentInformation from "./PastIncidentInformation";

export interface StatusItem {
  id: number;
  status: string;
  date: any;
  blocker_title: string;
  blocker_info: {
    resolved: string;
    monitoring: string;
  };
  [key: string]: any;
}
interface RootState {
  serviceData: {
    serviceData: StatusItem[];
    isLoading: boolean;
  };
}
const PastInsident = () => {
  const navigate = useNavigate();
  const { serviceData } = useSelector((store: RootState) => store.serviceData);

  const startDate = new Date();
  const endDate = subDays(startDate, 15);
  const dates = eachDayOfInterval({ start: endDate, end: startDate });
  const ReversedDates = [...dates].reverse();
  return (
    <Box>
      <Typography
        sx={{
          color: "#000",
          fontWeight: "600",
          fontSize: { xs: "1.5rem", sm: "1.5rem", md: "2rem" },
          margin: "3rem 0",
        }}
      >
        Past Incidents
      </Typography>
      <Box>
        {ReversedDates.map((date, index) => {
          const matchingData = serviceData.filter(
            (item) =>
              format(parseISO(item.date), "MM/dd/yyyy") ===
              format(date, "MM/dd/yyyy")
          );

          return (
            <PastIncidentInformation
              key={index * 2}
              date={date}
              matchingData={matchingData}
            />
          );
        })}
      </Box>
      <Typography
        sx={{
          color: "#608BFB",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => navigate("/history")}
      >
        <KeyboardBackspaceIcon sx={{ marginRight: "0.5rem" }} /> Incident
        history
      </Typography>
    </Box>
  );
};

export default PastInsident;
