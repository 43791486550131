import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import Uptime from "../components/historyComponent/Uptime";
import Incidents from "../components/historyComponent/incident/Incidents";

const RecentHistory = () => {
  const navigate = useNavigate();
  const [toggleComponent, setToggleComponent] = useState(true);
  return (
    <Box>
      <Box>
        <Box
          sx={{
            borderBottom: "1px solid #D7E4F0",
            display: "flex",
            padding: "1.5rem 0 ",
            marginBottom: { xs: "2rem" },
          }}
        >
          <Box sx={{ padding: "0 2rem 0 2rem " }}>
            <Box
              sx={{
                color: toggleComponent ? "#000" : "#8CA1B6",
                cursor: "pointer",
                borderRadius: toggleComponent ? "4px" : "0 solid #E97D80",
              }}
              onClick={() => setToggleComponent(true)}
            >
              Uptime
              {toggleComponent && (
                <Box
                  component="hr"
                  sx={{
                    width: "100%",
                    height: "3px",
                    backgroundColor: "#E97D80",
                    borderColor: "#E97D80",
                    borderRadius: "5px",
                  }}
                />
              )}
            </Box>
          </Box>

          <Box
            sx={{
              color: !toggleComponent ? "#000" : "#8CA1B6",
              cursor: "pointer",
            }}
            onClick={() => setToggleComponent(false)}
          >
            Incidents
            {!toggleComponent && (
              <Box
                component="hr"
                sx={{
                  width: "100%",
                  height: "3px",
                  backgroundColor: "#E97D80",
                  borderColor: "#E97D80",
                  borderRadius: "5px",
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box>{toggleComponent ? <Uptime /> : <Incidents />}</Box>
      <Typography
        sx={{
          color: "#608BFB",
          display: "flex",
          alignItems: "center",
          margin: {
            xs: "3rem 0 2rem 0",
            sm: "3rem 0 2rem 0",
            md: "6rem 0 2rem 0",
          },
          cursor: "pointer",
        }}
        onClick={() => navigate("/")}
      >
        <KeyboardBackspaceIcon sx={{ marginRight: "0.5rem" }} /> Current status
      </Typography>
    </Box>
  );
};

export default RecentHistory;
