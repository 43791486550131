import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { format, addMonths, endOfMonth, startOfMonth } from "date-fns";
import "react-date-range/dist/styles.css"; 
import "react-date-range/dist/theme/default.css"; 
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useSelector } from "react-redux";
import {
  handleNextMonths,
  handlePreviousMonths,
  renderMonthData,
} from "./IncidentFunctions";

interface StatusItem {
  id: number;
  status: string;
  date: any;
  blocker_title: string;
  blocker_info: {
    resolved: string;
    monitoring: string;
  };
  [key: string]: any;
}
interface RootState {
  serviceData: {
    serviceData: StatusItem[];
    isLoading: boolean;
  };
}
const Incidents: React.FC = () => {
  const { serviceData } = useSelector((store: RootState) => store.serviceData);

  const currentDate = new Date();
  const endDate = currentDate;
  const [currentMonth, setCurrentMonth] = useState(currentDate);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "1rem",
          justifyContent: {
            xs: "center",
          },
        }}
      >
        <Button
          variant="outlined"
          onClick={handlePreviousMonths(currentMonth, setCurrentMonth, endDate)}
          sx={{
            borderColor: "#C6C6C6",
            ":hover": {
              borderColor: "#C6C6C6",
            },
          }}
        >
          {/* Previous 3 Months */}
          <NavigateBeforeIcon
            sx={{
              color: "#C6C6C6",
              fontSize: "2rem",
              ":hover": {
                color: "#E97D80",
              },
            }}
          />
        </Button>
        <Typography
          variant="h6"
          sx={{
            marginLeft: "1rem",
            marginRight: "1rem",
            fontWeight: "400",
            fontSize: {
              xs: "0.8rem",
            },
          }}
        >
          {format(startOfMonth(currentMonth), "MMMM yyyy")} -{" "}
          {format(endOfMonth(addMonths(currentMonth, 2)), "MMMM yyyy")}
        </Typography>
        <Button
          variant="outlined"
          onClick={handleNextMonths(currentMonth, setCurrentMonth, endDate)}
          disabled={addMonths(currentMonth, 3) > endDate}
          sx={{
            borderColor: "#C6C6C6",
            ":hover": {
              borderColor: "#C6C6C6",
            },
          }}
        >
          {/* Next 3 Months */}
          <NavigateNextIcon
            sx={{
              color: "#C6C6C6",
              fontSize: "2rem",
              ":hover": {
                color: "#E97D80",
              },
            }}
          />
        </Button>
      </Box>
      {renderMonthData(currentMonth, setCurrentMonth, serviceData)}
    </Box>
  );
};

export default Incidents;
