import React, { useState } from "react";
import { eachDayOfInterval, format, parseISO } from "date-fns";
import { Box, useMediaQuery, Popover, Typography } from "@mui/material";

interface StatusItem {
  id: number;
  status: string;
  date: string;
  blocker_title: string;
  blocker_info: {
    resolved: string;
    monitoring: string;
  };
  [key: string]: any;
}

const YearlyCalendar: React.FC<{ data: StatusItem[] }> = ({ data }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const getStatusColor = (status: string) => {
    switch (status) {
      case "operational":
        return "gray";
      case "degraded":
        return "orange";
      case "partial outage":
        return "red";
      case "major outage":
        return "darkred";
      default:
        return "red";
    }
  };
  const isMobile = useMediaQuery("(max-width: 600px)");

  const startDate = new Date();
  isMobile
    ? startDate.setDate(startDate.getDate() - 29)
    : startDate.setDate(startDate.getDate() - 90);

  const endDate = new Date();

  const dates = eachDayOfInterval({ start: startDate, end: endDate });

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const [blockerTitle, setBlockerTitle] = useState<string>("");
  const [blockerInfo, setBlockerInfo] = useState<
    { resolved: string; monitoring: string } | undefined
  >(undefined);

  const handleHover = (date: Date, event: React.MouseEvent<HTMLDivElement>) => {
    setSelectedDate(date);
    setAnchorEl(event.currentTarget);
    const dateString = format(date, "dd-MM-yyyy");
    const matchingStatus = data.find((item) => {
      const itemDate = parseISO(item.date);
      const formattedItemDate = format(itemDate, "dd-MM-yyyy");
      return formattedItemDate === dateString;
    });

    if (matchingStatus) {
      setBlockerTitle(matchingStatus.blocker_title);
      setBlockerInfo(matchingStatus?.blocker_info);
    } else {
      setBlockerTitle("No data to display");
      setBlockerInfo(undefined);
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", overflow: "auto" }}>
        {dates.map((date, index) => {
          const dateString = format(date, "dd-MM-yyyy");
          const matchingStatus = data.find((item) => {
            const itemDate = parseISO(item.date);
            const formattedItemDate = format(itemDate, "dd-MM-yyyy");
            return formattedItemDate === dateString;
          });

          const statusColor = matchingStatus
            ? getStatusColor(matchingStatus.status)
            : "#65C971";

          const rectWidth = isMobile ? `${100 / 30}%` : `${100 / 90}%`;

          return (
            <React.Fragment key={index}>
              <Box
                key={index}
                component="div"
                sx={{
                  width: rectWidth,
                  backgroundColor: statusColor,
                  height: "40px",
                  display: "inline-block",
                  margin: "0 2px",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onMouseEnter={(e) => handleHover(date, e)}
              />

              {selectedDate &&
                format(selectedDate, "dd-MM-yyyy") === dateString && (
                  <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "400px",
                        backgroundColor: "white",
                        padding: "1rem",
                      }}
                    >
                      {selectedDate ? (
                        <>
                          <span>{format(selectedDate, "dd-MM-yyyy")}</span>
                          <Typography
                            sx={{
                              color: "#F3AF3C",
                              fontWeight: "bold",
                              marginBottom: "0.7rem",
                            }}
                          >
                            {blockerTitle}
                          </Typography>
                          {blockerInfo?.resolved && (
                            <Typography sx={{ color: "#BDBDBD" }}>
                              <span
                                style={{
                                  fontWeight: "500",
                                  color: "#000",
                                  fontSize: "1.2rem",
                                }}
                              >
                                Resolved:
                              </span>{" "}
                              {blockerInfo.resolved}
                            </Typography>
                          )}
                          {blockerInfo?.monitoring && (
                            <Typography
                              sx={{ marginTop: "0.7rem", color: "#BDBDBD" }}
                            >
                              <span
                                style={{ fontWeight: "500", color: "#000" }}
                              >
                                Monitoring:
                              </span>{" "}
                              {blockerInfo.monitoring}
                            </Typography>
                          )}
                        </>
                      ) : (
                        <p>No Data to display</p>
                      )}
                    </Box>
                  </Popover>
                )}
            </React.Fragment>
          );
        })}
      </Box>
    </React.Fragment>
  );
};

export default YearlyCalendar;
