import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { StatusItem } from "./PastInsident";

interface Informationprops {
  date: Date;
  matchingData: StatusItem[];
}

const PastIncidentInformation = ({ date, matchingData }: Informationprops) => {
  return (
    <Box
      sx={{
        margin: "2rem 0",
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: "1rem", md: "1.2rem" },
          fontWeight: "bold",
        }}
      >
        {format(date, "MMMM d, yyyy")}
      </Typography>
      <Box
        component="hr"
        sx={{
          width: "100%",
          height: "2px",
          color: "#D9D9D9",
          margin: "1rem 0",
        }}
     />
      {matchingData.length > 0 ? (
        matchingData.map((data: StatusItem) => {
          return (
            <Box key={data.id} sx={{ marginBottom: "1rem" }}>
              <Typography
                sx={{
                  color: "#F3AF3C",
                  fontSize: { xs: "1rem", md: "1.7rem" },
                  fontWeight: "600",
                  marginBottom: {
                    md: "0.7rem",
                  },
                }}
              >
                {data.blocker_title}
              </Typography>
              <Box
                sx={{
                  color: "#BDBDBD",
                  fontSize: { xs: "1rem", md: "1.2rem" },
                  lineHeight: "35px",
                }}
              >
                {data.blocker_info ? (
                  <Box>
                    <Typography>
                      <span style={{ color: "#000" }}> Monitoring:</span>{" "}
                      {data.blocker_info.monitoring}{" "}
                    </Typography>
                    <Typography>
                      <span style={{ color: "#000" }}> Resolved:</span>{" "}
                      {data.blocker_info.resolved}{" "}
                    </Typography>
                  </Box>
                ) : (
                  "No incidents reported."
                )}
              </Box>
            </Box>
          );
        })
      ) : (
        <Typography
          sx={{
            color: "#BDBDBD",
            fontSize: { xs: "1rem", md: "1.2rem" },
          }}
        >
          No data to display
        </Typography>
      )}
    </Box>
  );
};

export default PastIncidentInformation;
