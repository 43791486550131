
import React from "react";
import { addMonths, format, isAfter, isSameDay, parseISO } from "date-fns";
import { Box, DialogContentText } from "@mui/material";
import { createAction } from "@reduxjs/toolkit";

export interface StatusItem {
  id: number;
  status: string;
  date: any;
  blocker_title: string;
  blocker_info: {
    resolved: string;
    monitoring: string;
  };
  [key: string]: any;
}
export interface RootState {
  serviceData: {
    serviceData: StatusItem[];
    isLoading: boolean;
    service: string;
  };
}
export const SERVICE_CHOICES = [
  "all",
  "Dukka terminal transaction",
  "Dukka withdrawal",
  "Dukka deposits",
  "Dukka login",
  "Dukka registration",
  "Third party dashBoard",
  "Dukka balance creation",
  "Dukka terminal request",
];

export const setService = createAction<string>("SET_SERVICE");

export const handlePrevClick = () => {
  setSelectedMonth((prevMonth) => addMonths(prevMonth, -3));
};

export const handleCloseDialog = (
  setOpenDialog: (open: boolean) => void
): void => {
  setOpenDialog(false);
};

export const handleNextClick = (
  selectedMonth: Date,
  setSelectedMonth: React.Dispatch<React.SetStateAction<Date>>
): void => {
  const nextMonth = addMonths(selectedMonth, 3);
  setSelectedMonth(nextMonth);
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case "operational":
      return "blue";
    case "degraded":
      return "orange";
    case "partial outage":
      return "red";
    case "major outage":
      return "darkred";
    case "past-tile":
      return "#65C971";
    default:
      return "#65C971";
  }
};

export const tileClassName =
  (serviceData: StatusItem[], currentDate: Date, format: any, parseISO: any) =>
  ({ date }: { date: Date }) => {
    const dateString = format(date, "dd-MM-yyyy");

    const statusItem = serviceData.find((item) => {
      const formattedDate = format(parseISO(item.date), "dd-MM-yyyy");
      return formattedDate === dateString;
    });

    if (statusItem) {
      const color = getStatusColor(statusItem.status);
      return `react-calendar__tile status-tile ${color}`;
    }
    if (isAfter(date, currentDate)) {
      return "react-calendar__tile future-tile";
    }
    return "default-color ";
  };

export const handleTileClick = (
  date: Date,
  excludedDate: Date | null,
  serviceData: StatusItem[],
  setSelectedBlocker: (blockers: StatusItem[] | null) => void,
  setSelectedDate: (date: Date | null) => void,
  setOpenDialog: (open: boolean) => void,
  setExcludedDate: (date: Date | null) => void,
  format: any
) => {
  if (
    isAfter(date, new Date()) ||
    (excludedDate && isSameDay(date, excludedDate))
  ) {
    setExcludedDate(null);
  } else {
    const dateString = format(date, "dd-MM-yyyy");
    const blockers = serviceData.filter(
      (item) => format(parseISO(item.date), "dd-MM-yyyy") === dateString
    );
    setSelectedBlocker(blockers);
    setSelectedDate(date);
    setOpenDialog(true);
    setExcludedDate(date);
  }
};

export const renderDialogContent = (selectedBlocker: StatusItem[] | null) => {
  if (selectedBlocker && selectedBlocker.length > 0) {
    return (
      <>
        {selectedBlocker.map((blocker: any) => (
          <Box key={blocker.id} sx={{ marginBottom: "0.7rem" }}>
            <DialogContentText
              sx={{
                color: "#F3AF3C",
                fontWeight: "bold",
                marginBottom: "0.4rem",
              }}
            >
              {blocker.blocker_title}
            </DialogContentText>
            <DialogContentText>
              {blocker.blocker_info.resolved}
            </DialogContentText>
            <DialogContentText>
              {blocker.blocker_info.monitoring}
            </DialogContentText>
          </Box>
        ))}
      </>
    );
  } else {
    return <DialogContentText>No data to display</DialogContentText>;
  }
};

export const formatMonthLabel = (date: Date): string => {
  const firstMonth = format(date, "MMMM yyyy");
  const thirdMonth = format(addMonths(date, 2), "MMMM yyyy");

  return `${firstMonth} - ${thirdMonth}`;
};
function setSelectedMonth(arg0: (prevMonth: any) => Date) {
  throw new Error("Function not implemented.");
}
