import { Box, Typography } from "@mui/material";
import {
  startOfMonth,
  endOfMonth,
  isWithinInterval,
  parseISO,
  parse,
  subMonths,
  addMonths,
  eachMonthOfInterval,
  format,
} from "date-fns";

const getMonthData = (monthStart: Date, monthEnd: Date, serviceData: any[]) => {
  const monthData = serviceData.filter((item) => {
    const startDate = startOfMonth(monthStart);
    const endDate = endOfMonth(monthEnd);
    return isWithinInterval(parseISO(item.date), {
      start: startDate,
      end: endDate,
    });
  });

  monthData.sort((a, b) => {
    const dateA = parse(a.date, "MM-dd-yyyy", new Date());
    const dateB = parse(b.date, "MM-dd-yyyy", new Date());
    return dateB.getTime() - dateA.getTime();
  });

  return monthData;
};

export const handlePreviousMonths =
  (
    currentMonth: Date,
    setCurrentMonth: React.Dispatch<React.SetStateAction<Date>>,
    endDate: Date
  ): React.MouseEventHandler<HTMLButtonElement> =>
  (event) => {
    const previousMonth = subMonths(currentMonth, 3);
    setCurrentMonth(previousMonth);
  };

export const handleNextMonths =
  (
    currentMonth: Date,
    setCurrentMonth: React.Dispatch<React.SetStateAction<Date>>,
    endDate: Date
  ): React.MouseEventHandler<HTMLButtonElement> =>
  (event) => {
    const nextMonth = addMonths(currentMonth, 3);
    if (nextMonth <= endDate) {
      setCurrentMonth(nextMonth);
    }
  };

export const renderMonthData = (
  currentMonth: Date,
  setCurrentMonth: React.Dispatch<React.SetStateAction<Date>>,
  serviceData: any[]
) => {
  const startMonth = startOfMonth(currentMonth);
  const endMonth = endOfMonth(addMonths(currentMonth, 2));
  const monthRange = eachMonthOfInterval({
    start: startMonth,
    end: endMonth,
  });

  return monthRange.map((month, index) => {
    const monthStart = startOfMonth(month);
    const monthEnd = endOfMonth(month);
    const monthData = getMonthData(monthStart, monthEnd, serviceData);

    return (
      <Box key={index * 2} sx={{ marginBottom: "2rem" }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", marginBottom: "1rem" }}
        >
          {format(monthStart, "MMMM")}
        </Typography>
        {monthData.length > 0 ? (
          monthData
            .filter((matchingData) => matchingData.blocker_title !== "")
            .map((matchingData, dateIndex) => (
              <Box key={dateIndex} sx={{ marginBottom: "1rem" }}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", marginBottom: "0.5rem" }}
                ></Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#F3AF3C",
                    fontWeight: "600",
                    marginBottom: "0.7rem",
                  }}
                >
                  {matchingData.blocker_title}
                </Typography>
                <Box sx={{ color: "#BDBDBD" }}>
                  {Object.keys(matchingData.blocker_info).length ? (
                    <Typography sx={{ color: "#BDBDBD" }}>
                      {
                        (
                          matchingData.blocker_info as {
                            resolved: string;
                            monitoring: string;
                          }
                        ).resolved
                      }
                    </Typography>
                  ) : (
                    <Typography sx={{ color: "#BDBDBD" }}>
                      No incidents reported.
                    </Typography>
                  )}
                </Box>
              </Box>
            ))
        ) : (
          <Typography sx={{ color: "#BDBDBD" }}>No data to display</Typography>
        )}
      </Box>
    );
  });
};
