import React, { useState } from "react";
import { format, addMonths, parseISO } from "date-fns";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import "../../../App.css";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useDispatch, useSelector } from "react-redux";
import { getServiceData, setService } from "../../../feature/serviceDataSlice";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import {
  SERVICE_CHOICES,
  handleCloseDialog,
  handleNextClick,
  tileClassName,
  handleTileClick,
  renderDialogContent,
  formatMonthLabel,
  RootState,
  StatusItem,
} from "./CalenderFunctions";

const CalendarComponent: React.FC = () => {
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedBlocker, setSelectedBlocker] = useState<StatusItem[] | null>(
    null
  );
  const [excludedDate, setExcludedDate] = useState<Date | null>(null);
  const dispatch: AppDispatch = useDispatch();
  const { serviceData, service } = useSelector(
    (store: RootState) => store.serviceData
  );

  type AppDispatch = ThunkDispatch<RootState, undefined, AnyAction>;

  const changeOption = (event: SelectChangeEvent) => {
    const selectedService = event.target.value;
    dispatch(setService(selectedService));
    if (selectedService !== "all") {
      dispatch(getServiceData(selectedService));
    } else {
      dispatch(getServiceData());
    }
  };

  const handlePrevClick = () => {
    setSelectedMonth((prevMonth) => addMonths(prevMonth, -3));
  };

  const formatShortWeekday = (_: any, date: any) => "";

  return (
    <div>
      <Box
        sx={{
          display: {
            sm: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            marginBottom: "1.5rem",
            flexBasis: "50%",
          }}
        >
          <FormControl
            sx={{
              m: 1,
              width: "300px",
            }}
          >
            <Select
              value={service}
              onChange={changeOption}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  padding: "7px 7px",
                },
              }}
            >
              {SERVICE_CHOICES.map((item, index) => {
                return (
                  <MenuItem key={index * 3} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ flexBasis: { sm: "40%", md: "35%", lg: "27%" } }}>
          <div
            className="calendar-navigation"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              marginBottom: "2rem",
            }}
          >
            <button
              onClick={handlePrevClick}
              style={{
                backgroundColor: "white",
                border: "1px solid #C6C6C6",
                borderRadius: "3px",
              }}
            >
              <NavigateBeforeIcon
                sx={{
                  paddingTop: "3px",
                  color: "#C6C6C6",
                }}
              />
            </button>
            {/* Left arrow */}
            <div
              className="month-label"
              style={{
                fontSize: "0.9rem",
                paddingTop: "3px",
              }}
            >
              {formatMonthLabel(selectedMonth)}
            </div>
            <button
              onClick={() => handleNextClick(selectedMonth, setSelectedMonth)}
              style={{
                backgroundColor: "white",
                border: "1px solid #C6C6C6",
                borderRadius: "3px",
              }}
            >
              <NavigateNextIcon
                sx={{
                  paddingTop: "3px",
                  color: "#C6C6C6",
                }}
              />
            </button>
            {/* Right arrow */}
          </div>
        </Box>
      </Box>

      <Box className="calendar-grid">
        <Grid container columnSpacing={4}>
          {[1, 2, 3].map((val) => {
            return (
              <Grid item key={val} sm={6} md={4}>
                <Calendar
                  value={
                    val === 1
                      ? selectedMonth
                      : val === 2
                      ? addMonths(selectedMonth, 1)
                      : val === 3
                      ? addMonths(selectedMonth, 2)
                      : null
                  }
                  tileClassName={tileClassName(
                    serviceData,
                    new Date(),
                    format,
                    parseISO
                  )}
                  showNeighboringMonth={false}
                  prev2Label={null}
                  next2Label={null}
                  formatShortWeekday={formatShortWeekday}
                  onClickDay={(date) =>
                    handleTileClick(
                      date,
                      excludedDate,
                      serviceData,
                      setSelectedBlocker,
                      setSelectedDate,
                      setOpenDialog,
                      setExcludedDate,
                      format
                    )
                  }
                />
              </Grid>
            );
          })}
        </Grid>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>
            {selectedDate && format(selectedDate, "dd-MM-yyyy")}
          </DialogTitle>
          <DialogContent>{renderDialogContent(selectedBlocker)}</DialogContent>
          <DialogActions>
            <Button onClick={() => handleCloseDialog(setOpenDialog)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
};

export default CalendarComponent;
