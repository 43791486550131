import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

interface ServiceProps {
  serviceData: StatusItem[];
  isLoading: boolean;
  service?: string;
}

const initialState: ServiceProps = {
  serviceData: [],
  isLoading: true,
  service: "all",
};

interface StatusItem {
  id: number;
  status: string;
  date: any;
  blocker_title: string;
  blocker_info: {
    resolved: string;
    monitoring: string;
  };
  [key: string]: any;
}

export const getServiceData = createAsyncThunk(
  "serviceData/getServiceData",
  async (selectedService: string | undefined, thunkAPI) => {
    let url = `https://service.dukka.com/api/v1/auth/service_reports`;

    //let url = `https://microservice-testing.dukka.com/api/v1/auth/service_reports`;
    if (selectedService) {
      url += `?service_name=${selectedService}`;
    }

    try {
      const res = await axios.get<any>(url);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Ooopppsss! Something went wrong");
    }
  }
);

const serviceDataSlice = createSlice({
  name: "serviceData",
  initialState,
  reducers: {
    setService: (state, action: PayloadAction<string>) => {
      state.service = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getServiceData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getServiceData.fulfilled,
      (state, action: PayloadAction<StatusItem[]>) => {
        state.isLoading = false;
        state.serviceData = action.payload;
      }
    );
    builder.addCase(getServiceData.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setService } = serviceDataSlice.actions;
export default serviceDataSlice.reducer;
